h1 > span:first-child {
  font-size: 40px;
}

.find_out_more button {
  opacity: 0;
  transition: 0.3s all;
}
.find_out_more:hover button {
  opacity: 1;
}
