.share:hover + .dropdown {
	display: flex;
}
.dropdown:hover {
	display: flex !important;
}
.share_btn:hover > .share_btn_tooltip {
	display: flex !important;
}
.share_btn_tooltip {
	padding-top: 10px;
	position: absolute;
	width: 300px;
	top: 32px;
	left: -100px;
	display: none;
}
