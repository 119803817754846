.blurred_bg {
	display: flex;
	align-items: center;
	justify-content: center;
	filter: blur(8px);
	position: absolute;
	inset: 0;
	z-index: 10;
}
.find_out_more button {
	opacity: 0;
	transition: 0.3s all;
}
.find_out_more:hover button {
	opacity: 1;
}
