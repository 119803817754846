.card {
  /* max-width: 1026px; */
  padding: 16px 24px 8px;
  box-shadow: 0px 8px 16px rgba(28, 49, 68, 0.15);
  border-radius: 6px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  transition: all 0.5s;
}

.hidden {
  transition: all 0.5s;
}

.show {
  transition: all 0.5s;
}

.toggleDetail {
  position: absolute;
  bottom: 0;
  width: 100%;
  right: 0;
  margin-bottom: 0;
}
