.radio input[type="radio"] {
  -webkit-appearance: none;
  appearance: none;
  background-color: var(--form-background);
  margin: 0;
  border: 2px solid rgba(241, 245, 249, 0.5);
  border-radius: 50%;
  transform: translateY(-0.075em);
  background: rgba(148, 163, 184, 0.1);
  display: grid;
  place-content: center;
  cursor: pointer;
}
.radio input[type="radio"]::before {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em #f1f5f9;
  background-color: #f1f5f9;
}

.radio input[type="radio"]:checked::before {
  transform: scale(1);
}

.radio input[type="radio"]:checked {
  border-color: #f1f5f9;
}
