.scorecard {
  /* max-width: 1026px; */
  box-shadow: 0px 2px 8px 1px rgba(28, 49, 68, 0.1);
}
.scorecard > div > p {
  overflow: hidden;
  transition: 0.3s all;
}
.show {
  max-height: 1000px;
}
.hide {
  max-height: 25px;
}
