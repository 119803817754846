.loading {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading > img {
  height: 40px;
}
.loading > p {
  font-size: 18px;
}
.pdf {
  height: 90vh;
  width: 90%;
}
.wrap {
  height: 100vh;
  width: 100%;
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}
