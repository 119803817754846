/* .main {
  max-width: 1026px;
} */
.main img {
  padding: 0;
  padding-right: 6px;
}
.main button {
  transition: 0.3s all;
}
.main button:active {
  box-shadow: 0px 0px 3px 1px rgba(255, 255, 255, 0.75);
}
